import axios from 'src/app-axios';
import Constants from 'src/constants';

export const jobs = {
  namespaced: true,
  state: {
    paginatedJobs: [],
    joblist: [],
    jobDetails: [],
    jobComments: [],
    jobRequests: [],
    mileStones: [],
    jobsForNotify: [],
    publishedJobList: [],
    enablePublish: false,
    scheduleVersions: [],
    milestones: [],
    scheduleTemplates: [],
    scheduleTemplateDetails: [],
  },
  getters: {
    getPaginatedJobs (state) {
      return state.paginatedJobs;
    },
    getJobList (state) {
      return state.joblist;
    },
    getPublishedJobList (state) {
      return state.publishedJobList;
    },
    getJobDetails (state) {
      return state.jobDetails;
    },
    getJobComments (state) {
      return state.jobComments;
    },
    getJobRequest (state) {
      return state.jobRequests;
    },
    getJobs (state) {
      return state.mileStones;
    },
    getJobsForNotify (state) {
      return state.jobsForNotify;
    },
    getEnablePublish (state) {
      return state.enablePublish;
    },
    getScheduleVersions (state) {
      return state.scheduleVersions;
    },
    getMilestones (state) {
      return state.milestones;
    },
    getScheduleTemplates (state) {
      return state.scheduleTemplates;
    },
    getScheduleTemplateDetails (state) {
      return state.scheduleTemplateDetails;
    },
  },
  mutations: {
    setJobs (state, data) {
      state.joblist = data.data ? data.data : data;
    },
    setPublishedJobs (state, data) {
      state.publishedJobList = data.data;
    },
    setPublishedJobsversion (state, data) {
      state.publishedJobList = data;
    },
    setPaginatedJobs (state, data) {
      state.paginatedJobs = data;
    },
    setJobDetails (state, data) {
      state.jobDetails = data;
    },
    setJobComments (state, data) {
      state.jobComments = data.data;
    },
    setJobRequests (state, data) {
      state.jobRequests = data;
    },
    setNotifyJobs (state, data) {
      state.mileStones = data.data;
    },
    setJobsForNotify (state, data) {
      state.jobsForNotify = data.data;
    },
    setEnablePublish (state, data) {
      state.enablePublish = data;
    },
    setScheduleVersions (state, data) {
      state.scheduleVersions = data;
    },
    setPublishedVersionJob (state, data) {
      state.publishedJobList = data;
    },
    setMilestones (state, data) {
      state.milestones = data;
    },
    setScheduleTemplates (state, data) {
      state.scheduleTemplates = data;
    },
    setScheduleTemplateDetails (state, data) {
      state.scheduleTemplateDetails = data;
    },
  },
  actions: {
    async fetchPaginatedJobs ({ dispatch, commit }, payload) {
      const url = payload.publicStatus
        ? `api/projects/${payload.projectId}/jobs`
        : `api/projects/${payload.projectId}/job-drafts`;
      try {
        const jobs = await axios.get(url, payload.params);
        commit('setPaginatedJobs', jobs.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the jobs list.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async fetchJobsByProject ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectid}/job-drafts`;
      try {
        const response = await axios.get(url, payload.params);
        commit('setJobs', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the job lists.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async fetchPublicJobsByProject ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectid}/jobs`;
      try {
        const response = await axios.get(url, payload);
        commit('setPublishedJobs', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the job lists.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async createJob ({ dispatch }, payload) {
      const url = 'api/jobs';
      try {
        const response = await axios.post(url, payload);
        return response.data;
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in creating this project.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async JobDetails ({ dispatch, commit }, payload) {
      const url =
        payload.jobTypes === 'draft'
          ? `api/job-drafts/${payload.jobId}`
          : `api/jobs/${payload.jobId}`;
      try {
        const response = await axios.get(url, payload);
        commit('setJobDetails', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the job lists.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async updateJob ({ dispatch }, payload) {
      const url = `api/jobs/${payload.jobId}`;
      try {
        await axios.put(url, payload);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in update this job.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async deleteJob ({ dispatch }, payload) {
      const url = `api/jobs/${payload.jobId}`;
      try {
        await axios.delete(url, payload);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in deleting this job.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async addJobComment ({ dispatch }, payload) {
      const url = `api/jobs/${payload.formData.get('jobId')}/comments`;
      try {
        await axios.post(url, payload.formData);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in add comment.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async fetchJobComments ({ dispatch, commit }, payload) {
      const url = `api/jobs/${payload.jobId}/comments`;
      try {
        const response = await axios.get(url, payload);
        commit('setJobComments', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the comments.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async fetchJobsRequests ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectId}/job-requests`;
      try {
        const response = await axios.get(url, payload.params);
        commit('setJobRequests', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in getting the job requests.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
      }
    },
    async createRequest ({ dispatch }, payload) {
      const url = `api/jobs/${payload.jobId}/job-requests`;
      try {
        await axios.post(url, payload);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in creating job request.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async updateRequest ({ dispatch }, payload) {
      const url = `api/jobs/job-requests/${payload.reqId.id}`;
      try {
        await axios.put(url, payload);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in update this job.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async fetchJobs ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectId}/jobs`;
      try {
        const response = await axios.get(url, payload.params);
        commit('setNotifyJobs', response.data);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in fetching the jobs.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
    async sendNotification ({ dispatch }, payload) {
      const url = `/api/projects/${payload.projectId}/job-notifications`;
      try {
        await axios.post(url, payload.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong is senting notification',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async updateEnablePublish ({ commit }, payload) {
      commit('setEnablePublish', payload);
    },
    async fetchVersions ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectId}/job-versions`;
      try {
        const response = await axios.get(url, payload.params);
        commit('setScheduleVersions', response.data);
      } catch (exception) {
        dispatch(
          'alert/onAlert',
          {
            message: 'Something went wrong in fetxhing the jobs.',
            type: Constants.ALERT_TYPE_ERROR,
          },
          { root: true },
        );
        throw exception;
      }
    },
    async fetchMilestonesByProject ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectId}/jobs`;
          try {
            const response = await axios.get(url, payload.params);
            commit('setMilestones', response.data);
          } catch (exception) {
            dispatch('alert/onAlert', {
              message: 'Something went wrong in getting the job lists.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
          }
        },
    versionSetJobs ({ commit }, payload) {
      commit('setPublishedJobsversion', payload.jobs);
    },
    async saveScheduleTemplate ({ dispatch, commit }, payload) {
      const url = `api/projects/${payload.projectId}/templates`;
      try {
        await axios.post(url, payload);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in fetching the jobs.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
    async fetchScheduleTemplates ({ dispatch, commit }, payload) {
      const url = '/api/organizations/job-layouts';
      try {
        const response = await axios.get(url, payload);
        commit('setScheduleTemplates', response.data);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in fetching the jobs.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
    async importScheduleLayout ({ dispatch, commit }, payload) {
      const url = `api/job-layouts/${payload.layoutId}/publish`;
      try {
        await axios.post(url, payload);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in fetching the jobs.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
    async fetchLayoutDetails ({ dispatch, commit }, payload) {
      const url = `api/job-layouts/${payload.layoutId}/details`;
      try {
        const response = await axios.get(url, payload);
        commit('setScheduleTemplateDetails', response.data);
      } catch (exception) {
          dispatch('alert/onAlert', {
              message: 'Something went wrong in fetching the jobs.',
              type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          throw exception;
      }
    },
},
};
